import { doSubscribeAjax, isFormValid } from "./util";

function Modal() {
	this.$modal = $("#newsletter-subscribe-modal");

	this.init = () => {
		const displayed = localStorage.getItem("ess_nl_popup_displayed");
		if (this.$modal.length && !displayed) {
			this.show();
			this.register();
		} else {
			this.remove();
		}
	};

	this.show = () => {
		$(document).one("scroll", () => {
			this.$modal.fadeIn();
			$("body").addClass("modal-open");
		});
	};

	this.remove = () => {
		this.$modal.remove();
	};

	this.close = () => {
		this.$modal.fadeOut(400, this.remove);
		$("body").removeClass("modal-open");
		localStorage.setItem("ess_nl_popup_displayed", true);
	};

	this.register = () => {
		this.$modal.find(".close").on("click", this.close);
		this.$modal.find(".subscribe-form").on("submit", (e) => this.submit(e));
	};

	this.submit = (e) => {
		e.preventDefault();
		const $form = $(e.target);

		if (isFormValid($form, false)) {
			doSubscribeAjax($form, () => setTimeout(this.close, 3000));
		}
	};
}

export default new Modal();
