/**
 * All global frontend scripts required should be included here.
 *
 * This is the file that Webpack is compiling into main.[hash].js
 */

import "../../pubstack/assets/tvguide"; // Load before footer scss
import "./sass/footer.scss";

import expandedMenu from "./js/expanded-menu";
import VideoJw from "../../pubstack/assets/js/video-jw";
import IasVideo from "../../pubstack/assets/js/ads/ias-video";
import articleOembed from "./js/article/article-oembed";
import EmailPreferences from "./js/newsletter/preferences";
import EmailUnsubscribe from "./js/newsletter/unsubscribe";
import SubscribeModal from "./js/newsletter/modal";
import NewsletterBlock from "./js/newsletter/block";
import LoadMore from "./js/loadmore"; // 2KB
new IasVideo();

require("../../pubstack/assets/js/ads/cls");
require('./js/newsletter/cordial-footer');

new VideoJw().init();

$(function () {
  setTimeout(function () {
  expandedMenu();
  articleOembed();

  // Newsletter Subscribe
  EmailPreferences.init();
  SubscribeModal.init();
  EmailUnsubscribe.init();
  NewsletterBlock.init();
  LoadMore.init();
  }, 0);
});
