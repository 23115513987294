import { doSubscribeAjax, isFormValid } from "./util";

function Preferences() {
	this.$form = $("#email-preferences .subscribe-form");

	this.init = () => {
		if (this.$form.length) {
			this.register();
		}
	};

	this.register = () => {
		this.$form.on("submit", (e) => this.submit(e));
	};

	this.submit = (e) => {
		e.preventDefault();

		if (isFormValid(this.$form, false)) {
			doSubscribeAjax(this.$form, null, null, () => {
				if (!this.$form.data("redirect")) {
					$("html, body").animate({ scrollTop: 0 }, 500);
				}
			});
		}
	};
}

export default new Preferences();
