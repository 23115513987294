import { doSubscribeAjax, isFormValid } from "./util";

/**
 * Homepage Subscribe Block
 *
 * On sign up button click, it goes to the /newsletters page with prefilled values.
 */
function Block() {
	this.$form = $("#block-newsletter-signup .subscribe-form");

	this.init = () => {
		if (this.$form.length) {
			this.register();
		}
	};

	this.register = () => {
		this.$form.on("submit", (e) => this.submit(e));
	};

	this.submit = (e) => {
		e.preventDefault();

		if (isFormValid(this.$form, false)) {
			doSubscribeAjax(this.$form, null, null, null);
		}
	};
}

export default new Block();
