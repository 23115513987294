/**
 * Infinite scroll behavior on posts section.
 *
 * Load {pagesToShow} pages by scroll
 * - on page load
 * - each time user clicks `Load More` button
 */
function LoadMore() {
	this.loadnext = $(".loadnext");
	this.viewnext = $(".viewnext");
	this.loadByScroll = true;
	this.loading = false;
	this.nextpage = 2;
	this.totalPages = this.loadnext.data("total_pages");
	this.clickCounter = 0;

	this.init = () => {
		if (this.loadnext.length) {
			this.initObserver();
			this.register();
		}
	};

	this.initObserver = () => {
		const observer = new IntersectionObserver(
			(entries, observer) => {
				entries.forEach((entry) => {
					if (entry.intersectionRatio > 0) {
						this.load(true);
					}
				});
			},
			{
				threshold: 0.1,
			}
		);
		observer.observe(this.loadnext.get(0));
	};

	this.register = () => {
		this.viewnext.on("click", (e) => {
			e.preventDefault();
			this.load(false);
		});
	};

	this.load = (isScroll) => {
		if (this.loading || (isScroll && !this.loadByScroll)) {
			return;
		}

		this.loading = true;
		this.loadnext.removeClass("hidden");
		this.viewnext.addClass("hidden");

		if (!isScroll) {
			this.clickCounter++;
		}

		const pagesToShow = parseInt(this.loadnext.data("pages_to_show"));
		const attributes = this.loadnext.data("attributes");

		let action = "";
		if (this.loadnext.hasClass("search")) {
			action = "search_scroll";
		} else if (this.loadnext.hasClass("archive")) {
			action = "archive_load_more";
		} else {
			return;
		}

		$.ajax({
			url: `${pubstack.ajaxUrl}?action=${action}`,
			dataType: "html",
			method: "POST",
			data: { ...attributes, page: this.nextpage },
		})
			.done((response) => {
				$(response).insertBefore(this.loadnext);
				this.loadnext.addClass("hidden");
				this.nextpage++;

				if (this.nextpage && this.nextpage > this.totalPages) {
					this.viewnext.remove();
					this.loadnext.remove();
					return;
				}

				if (this.nextpage - 1 === pagesToShow * (this.clickCounter + 1)) {
					this.viewnext.removeClass("hidden");
					this.loadByScroll = false;
				} else {
					this.viewnext.addClass("hidden");
					this.loadByScroll = true;
				}
			})
			.always(() => {
				this.loading = false;
			});
	};
}

export default new LoadMore();
