if (location.hostname === "girlsunited.essence.com") {
	(function (C, O, R, D, I, A, L) {
		(C.CordialObject = I),
			(C[I] =
				C[I] ||
				function () {
					(C[I].q = C[I].q || []).push(arguments);
				});
		(C[I].l = 1 * new Date()), (C[I].q = []), (A = O.createElement(R));
		(L = O.getElementsByTagName(R)[0]),
			(A.async = 1),
			(A.src = D),
			L.parentNode.insertBefore(A, L);
	})(window, document, "script", "//track.cordial.io/track.v2.js", "crdl");
	crdl("connect", "essence", {
		trackUrl: "//se.connect.essence.com",
		connectUrl: "//d.connect.essence.com",
		cookieDomain: "essence.com",
		cookieLife: 365,
	});
	let post_data = jQuery(".pubstack-post-header").first().data("post-info");
	let post_targ = jQuery(".pubstack-post-header").first().data("page_targeting");
	if (typeof post_data !== "undefined" && post_data !== "") {
		if (post_data.page_url === "https://girlsunited.essence.com/") {
			post_data.pagetype = "homepage";
		}
		crdl("event", "browse", {
			page_type: post_data.pagetype,
			page_name: post_data.post_title,
			page_url: post_data.page_url,
			category: post_targ.ch,
			tags: post_targ.tags,
		});
	} else {
		crdl("event", "browse", {
			page_type: "page",
			page_name: document.title,
			page_url: window.location,
			category: "",
			tags: "",
		});
	}
}
